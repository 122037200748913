import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Stack } from '@mui/material';
import AgGridWrapper from '../../../../../../components/AgGridWrapper/AgGridWrapper';
import useStore from '../../../../../../store';
import { format } from 'date-fns'; // Use date-fns for formatting dates
import DatePicker from 'react-datepicker'; // Import react-datepicker
import 'react-datepicker/dist/react-datepicker.css'; // Import CSS for DatePicker

interface DeveloperData {
  unique_order_templates: number;
  unique_orders: number;
  name: string;
  employee_id: string;
}

const DevelopersWithOrdersTemplates = observer(() => {
  const { REPORT } = useStore();
  const { fetchDevelopersWithOrdersTemplates } = REPORT;
  const [rowData, setRowData] = useState<DeveloperData[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), 0, 1)); // Default to the first day of the year
  const [endDate, setEndDate] = useState<Date>(new Date()); // Default to today

  const [columnDefs] = useState<any>([
    { headerName: 'Employee ID', field: 'employee_id', filter: 'agTextColumnFilter' },
    { headerName: 'Developer Name', field: 'name', filter: 'agTextColumnFilter' },
    { headerName: 'Unique Orders', field: 'unique_orders', filter: 'agNumberColumnFilter' },
    { headerName: 'Unique Order Templates', field: 'unique_order_templates', filter: 'agNumberColumnFilter' },
  ]);

  const fetchData = async () => {
    try {
      const from_date = format(startDate, 'yyyy-MM-dd');
      const to_date = format(endDate, 'yyyy-MM-dd');
      const response = await fetchDevelopersWithOrdersTemplates({ from_date, to_date });
      if (!response.error) {
        setRowData(response.data); // Set the API response data to rowData
      }
    } catch (error) {
      console.error('Error fetching developers data:', error);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, [startDate, endDate]);

  return (
    <>
    <Stack
    direction="row"
    justifyContent="center"
    alignContent="center"
    spacing={2}
    sx={{ my: '20px'}}
  >
    <Box>
      {/* Date Range Filter */}
      <div style={{ display: 'flex', alignItems: 'center',  marginLeft: '10px' }}>
        <span style={{ marginRight: '10px' }}>From:</span>
        <DatePicker
          selected={startDate}
          onChange={(date: Date | null) => setStartDate(date || new Date())}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="yyyy-MM-dd"
        />
        <span style={{ margin: '0 10px' }}>To:</span>
        <DatePicker
          selected={endDate}
          onChange={(date: Date | null) => setEndDate(date || new Date())}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate} // Ensure 'to_date' is after 'from_date'
          dateFormat="yyyy-MM-dd"
        />
        {/* <Button 
          onClick={fetchData}
          variant="contained"
          color="primary"
          sx={{ marginLeft: '10px' }}
        >
          Apply
        </Button> */}
      </div>
    </Box>
  </Stack>
    <Box sx={{ width: '80%', margin: '0 auto' }}>
        <AgGridWrapper type="clientSide" columnDefs={columnDefs} rowData={rowData} />
    </Box>
    </>
  );
});

export default DevelopersWithOrdersTemplates;
