import { Box, Tab, Tabs } from '@mui/material'
import React from 'react'
import { observer } from 'mobx-react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import CategoryRoundedIcon from '../../../../../assets/images/category.png';
import SubjectRoundedIcon from '../../../../../assets/images/options.png';
  
const tabRoutes = [
  { label: 'Domain Data', value: '/reports/data/domain-wise-orders', icon:<img src={CategoryRoundedIcon} alt='' className='list-icon'/> },
  { label: ' 5+ CRs', value: '/reports/data/orders-five-plus-iterations', icon:  <img src={CategoryRoundedIcon} alt=''  className='list-icon'/>},
  { label: 'New Clients', value: '/reports/data/new-clients', icon:  <img src={CategoryRoundedIcon} alt=''  className='list-icon'/>},
  { label: 'Developer with order Templates', value: '/reports/data/developers-with-orders-templates', icon:  <img src={CategoryRoundedIcon} alt=''  className='list-icon'/>},
  { label: 'Revenue Data', value: '/reports/data/domain-wise-revenue', icon:  <img src={CategoryRoundedIcon} alt=''  className='list-icon'/>},

  // Add more tabs if needed
];

const DataListing = observer(() => {
    const location = useLocation();
    const activeTabValue = tabRoutes.find(tab => location.pathname.startsWith(tab.value))?.value;

    return (
          <>
            {/* <h1>Report - Data</h1> */}
            <Box sx={{ width: '100%' }}>
            <Tabs value={activeTabValue} aria-label="icon label tabs example"  sx={{
            bgcolor: '#5c5c5c',
          }}>
              {tabRoutes.map(tab => (
                <Tab
                  key={tab.value}
                  icon={tab.icon}
                  label={tab.label}
                  value={tab.value}
                  component={Link}
                  to={tab.value}
                  sx={{
                    color: '#ffff',
                    fontSize: '10px',
                  }}
                />
              ))}
            </Tabs>
            </Box>
            <Outlet />
          </>
    )
})

export default DataListing