// sync below permissions constants with backend - /config/permissions.php & seeders/UserRolePermissionSeeder.php files.
 
const AllPermissionList =  {
	/*User*/
	'LIST_USERS'                      : "View All Users",
	// 'VIEW_USER'                    : "View User",
	'ADD_USER'                        : "Add New User",
	'EDIT_USER'                       : "Edit User's Details",

    /*Clients*/
	'LIST_CLIENTS'                    : "View All Clients",
	// 'VIEW_CLIENT'                  : "View Client",
	'ADD_CLIENT'                      : "Add New Client",
	'EDIT_CLIENT'                     : "Edit Client's Details",

    /*Quotes*/
	'LIST_QUOTES'                     : "View All Quotes",
	// 'VIEW_QUOTE'                   : "View Quote",
	'ADD_QUOTE'                       : "Add New Quote",
	'EDIT_QUOTE'                      : "Edit Quote's Details",

    /*Orders*/
	'LIST_ORDERS'                     : "View All Orders",
	
	/*checklists*/
	'LIST_CHECKLISTS'	  		      : "View All Checklists",
	// 'VIEW_CHECKLIST'               : "View Checklist",
	'ADD_CHECKLIST'                   : "Add New Checklist",
	'EDIT_CHECKLIST'                  : "Edit Checklist's Details",

    /*Order Change Requests*/
	'LIST_ORDER_CHANGE_REQUESTS'	  : "View All Order Change Requests",
	// 'VIEW_ORDER_CHANGE_REQUEST'    : "View Order Change Request",
	'ADD_ORDER_CHANGE_REQUEST'        : "Add New Order Change Request",
	'EDIT_ORDER_CHANGE_REQUEST'       : "Edit Order Change Request's Details",

	/*Order Backlogs*/
	'LIST_ORDER_BACKLOGS'	  		  : "View All Order Backlogs",
	// 'VIEW_ORDER_BACKLOG'           : "View Order Backlog",
	'ADD_ORDER_BACKLOG'               : "Add New Order Backlog",
	'EDIT_ORDER_BACKLOG'              : "Edit Order Backlog's Details",

	/*Escalations*/
	'LIST_ESCALATIONS'	  		      : "View All Escalations",
	// 'VIEW_ESCALATION'              : "View Escalation",
	'ADD_ESCALATION'                  : "Add New Escalation",
	'EDIT_ESCALATION'                 : "Edit Escalation's Details",

	/*Feedbacks*/
	'LIST_FEEDBACKS'	  		      : "View All Feedbacks",

    /*My Earning Points*/
	'LIST_EARNING_POINTS'	  		  : "View All Earning Points",

	/*Extra Points*/
	'LIST_EXTRA_POINTS'	  		      : "View All Extra Points",
	// 'VIEW_EXTRA_POINT'             : "View Extra Point",
	'ADD_EXTRA_POINT'                 : "Add New Extra Point",
	'EDIT_EXTRA_POINT'                : "Edit Extra Point's Details",

    /*Discussions*/
	'LIST_DISCUSSIONS'	  		      : "View All Discussions",
	'ADD_DISCUSSION'                  : "Add New Discussion",
	'EDIT_DISCUSSION'                 : "Edit Discussion's Details",

	/*Masters*/
    'ACCESS_ALL_MASTERS'	  		  : "Access All Masters",

	/*zones*/
	'LIST_ZONES'	  		          : "View All Zones",
	'ADD_ZONE'                        : "Add New Zone",
	'EDIT_ZONE'                       : "Edit Zone's Details",

	/*Reports*/
    'VIEW_REPORTS'	  		  : "Access All Reports",
}

export default AllPermissionList;