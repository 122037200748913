import axios from "axios";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { makeAutoObservable } from "mobx";
import { Notification } from "../../config/global";
import Message from "../../config/Message";

export default class RootStore {
  public login_redirect_timer?: any;

  constructor() {
    makeAutoObservable(this);
    this.setAxiosBaseUrl();
    window.addEventListener('storage', this.handleInvalidToken);
  }

  public handleInvalidToken = (e: any) => {
    if (e.key === 'token') {
      if (e.oldValue && !e.newValue) {
        window.location.href = "/";
        this.resetStore();
      } else if (!e.oldValue && e.newValue) {
        window.location.reload();
      }
    }
  }

  public setAxiosBaseUrl = (): void => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL || "http://localhost:8000/";
  };

  public setAxiosInterceptor = (): void => {
    try{
    axios.interceptors.request.use((config) => {
      let header: any = config.headers;
      header = {
        ...header,
        // Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const authToken = localStorage.getItem("token");
      const api_key = localStorage.getItem("api_key") ?? "";
      const app_id = localStorage.getItem("app_id") ?? "";

      header = authToken
        ? {
          ...header,
          Accept: "application/json",
          Authorization: "Bearer " + authToken,
        }
        : {
          ...header,
          Accept: "application/json",
        };
      if (api_key && app_id) {
        if (api_key !== "null" && app_id !== "null") {
          header = {
            ...header,
            apikey: api_key,
            appid: app_id,
          };
        }
      }
      config.headers = header;
      /** In dev, intercepts request and logs it into console for dev */
      return config;
    });

    axios.interceptors.response.use(
      (response) => {
        if (response?.data?.notify) {
          Notification.success({ message: response?.data?.notify });
        }
        return response;
      },
      // TODO: redirect to login page if unauthorized
      (e) => {        
        if (e.response) {
          if (e.response.data?.notify) {
            Notification.error({ message: e.response.data?.notify })
          }

          // if unathenthicated access (forcefully refresh and redirect) 
          if (e.response.status === 403) {
            this.resetStore();
            window.location.href = "/";
          }

          return Promise.reject(e);
        } else {
          Notification.error({
            message: Message.global.networkIssue,
          });
          return Promise.reject({
            response: {
              data:{
                  data: {
                    general: ["Network Error - Please check your internet connection"]
                  },
                  message: "Network Error - Please check your internet connection",
                }
            }
          });
        }
      }
    );
    }catch(err:any){
      console.error(err);
    }
  };

  public resetStore = (): void => {
    localStorage.clear();
  };

  public setAxiosHeaders = (access_token?: string): Promise<any> => {
    try{
      if (access_token) localStorage.setItem("token", access_token);
      const auth_token = localStorage.getItem("token");
      if (auth_token && auth_token !== "undefined") {
        return Promise.resolve(auth_token);
      } else {
        return Promise.reject();
      }
    }catch(err:any){
      console.error(err);
      return Promise.reject();
    }
  };

  public getServerListPayload = (params: IServerSideGetRowsParams) => {
    return {
      filter_data: params.request.filterModel,
      sort_data: params.request.sortModel,
      per_page: (params.request.endRow ?? 0) - (params.request.startRow ?? 0),
      page: Math.ceil(
        ((params.request.startRow ?? 0) + 1) /
        ((params.request.endRow ?? 0) - (params.request.startRow ?? 0))
      ),
    };
  };
}
