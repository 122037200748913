import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { observer } from 'mobx-react';
import useStore from '../../../../../../store';

// Register necessary components for Bar chart
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Define the types for the chart data
interface MonthlyOrder {
  month: string;
  count: number;
  total_usd_amount: number;
  year: string;
}

const TotalRevenue: React.FC = () => {
  const { REPORT } = useStore(); // Access the store

  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), 0, 1));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [error, setError] = useState<string>(''); // State for error messages
  const [chartData, setChartData] = useState<ChartData<'bar'>>({
    labels: [],
    datasets: [],
  });

  // Format the date to 'YYYY-MM-DD' for API request
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Combine fetching and validation logic into a single useEffect
  useEffect(() => {
    const fetchData = async () => {
      // Check if the date range exceeds one year
      const oneYearLater = new Date(startDate);
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
      if (endDate > oneYearLater) {
        setError('Please select a date range within one year.');
        return;
      }

      setError(''); // Clear error if valid

      // Format the dates for the API request
      const payload = {
        from_date: formatDate(startDate),
        to_date: formatDate(endDate),
      };

      try {
        const response = await REPORT.fetchTotalRevenue(payload);
        if (!response.error) {
          const { monthly_counts } = response.data;

          // Prepare labels and data for the chart
          const labels = monthly_counts.map((item: MonthlyOrder) => `${item.month}'${item.year.slice(-2)}`);
          const orderCounts = monthly_counts.map((item: MonthlyOrder) => item.count);
          const templateCounts = monthly_counts.map((item: MonthlyOrder) => item.total_usd_amount);

          // Update chart data with both datasets
          setChartData({
            labels,
            datasets: [
              {
                label: 'Number of Orders',
                data: orderCounts,
                backgroundColor: 'rgba(75, 192, 192, 0.7)',
                hidden: true,
              },
              {
                label: 'Total Revenue',
                data: templateCounts,
                backgroundColor: 'rgba(192, 75, 75, 0.7)',
              },
            ],
          });
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [REPORT, startDate, endDate]);

  // Define options for a stacked bar chart
  const options: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      x: {
        stacked: true, // Enable stacking for the x-axis
        title: {
          display: true,
          text: 'Months',
        },
      },
      y: {
        stacked: true, // Enable stacking for the y-axis
        title: {
          display: true,
          text: 'Values',
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  return (
    <div>
      {/* Date Range Filter */}
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <span style={{ margin: '0 10px' }}>From</span>
        <DatePicker
          selected={startDate}
          onChange={(date: Date | null) => setStartDate(date || new Date())}
          selectsStart
          startDate={startDate}
          endDate={endDate}
        />
        <span style={{ margin: '0 10px' }}>to</span>
        <DatePicker
          selected={endDate}
          onChange={(date: Date | null) => setEndDate(date || new Date())}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </div>
      {error && <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>} {/* Display error message */}
      {/* Stacked Bar Chart */}
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default observer(TotalRevenue);
