import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Grid, Container, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import OrderGraph from './OrderReport/OrderReport';
import TemplateReport from './TemplateReport/TemplateReport';
import 'react-datepicker/dist/react-datepicker.css';
import StepLineChart from './BacklogsReport/BacklogsReport';
import OrderVsBacklogs from './OrderVsBacklogs/OrderVsBacklogs';
import TotalRevenue from './TotalRevenue/TotalRevenue';

const Reports = observer(() => {
  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Reports
      </Typography>

      <Grid container spacing={4}>
        {/* Order Graph Section */}
        <Grid item xs={12} md={6}>
          <Box
            border={1}
            borderRadius={2}
            padding={2}
            boxShadow={2}
            style={{ height: '100%' }}
          >
            <Box
              sx={{
                backgroundColor: '#1976d2',
                padding: '8px 16px',
                borderRadius: '4px 4px 0 0',
                color: 'white',
              }}
            >
             <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Order Report</Typography>
            </Box>
            <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
            </Box>
            <OrderGraph />
          </Box>
        </Grid>

        {/* Template Report Section */}
        <Grid item xs={12} md={6}>
          <Box
            border={1}
            borderRadius={2}
            padding={2}
            boxShadow={2}
            style={{ height: '100%' }}
          >
            <Box
              sx={{
                backgroundColor: '#ff9800',
                padding: '8px 16px',
                borderRadius: '4px 4px 0 0',
                color: 'white',
              }}
            >
             <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Template Report
            </Typography>
            </Box>
            <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
            </Box>
            <TemplateReport />
          </Box>
        </Grid>

        {/* backlogs report */}
          {/* Order Graph Section */}
          <Grid item xs={12} md={6}>
          <Box
            border={1}
            borderRadius={2}
            padding={2}
            boxShadow={2}
            style={{ height: '100%' }}
          >
            <Box
              sx={{
                backgroundColor: '#ff9800',
                padding: '8px 16px',
                borderRadius: '4px 4px 0 0',
                color: 'white',
              }}
            >
             <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
             Orders VS Backlogs(month wise):
             </Typography>
            </Box>

            <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
            </Box>

            <StepLineChart />
          </Box>
        </Grid>

        {/* Template Report Section */}
        <Grid item xs={12} md={6}>
          <Box
            border={1}
            borderRadius={2}
            padding={2}
            boxShadow={2}
            style={{ height: '100%' }}
          >
            <Box
              sx={{
                backgroundColor: '#1976d2',
                padding: '8px 16px',
                borderRadius: '4px 4px 0 0',
                color: 'white',
              }}
            >
             <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
             Orders VS Change Request(month wise):
            </Typography>
            </Box>

            <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
            </Box>

            <OrderVsBacklogs />
          </Box>
        </Grid>

        {/* Order Graph Section */}
        <Grid item xs={12} md={12}>
          <Box
            border={1}
            borderRadius={2}
            padding={2}
            boxShadow={2}
            style={{ height: '100%' }}
          >
            <Box
              sx={{
                backgroundColor: '#1976d2',
                padding: '8px 16px',
                borderRadius: '4px 4px 0 0',
                color: 'white',
              }}
            >
             <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
             Total Revenue</Typography>
            </Box>

            <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
            </Box>

            <TotalRevenue />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
});

export default Reports;
