import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the plugin

// Register all necessary modules, including the plugin
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export const chartOptions = (data: number[]): ChartOptions<'bar'> => {
  // Calculate the max value dynamically and add some padding (e.g., 10%)
  const maxDataValue = Math.max(...data);
  const dynamicMax = Math.ceil(maxDataValue * 1.1); // Adding 10% padding

  return {
    responsive: true,
    scales: {
      x: {
        ticks: {
          autoSkip: false, // Ensure all labels are shown
        },
      },
      y: {
        beginAtZero: true, // Start the Y-axis at zero
        min: 0,            // Minimum value for Y-axis
        max: dynamicMax,   // Use dynamic max value here
        ticks: {
          stepSize: Math.ceil(dynamicMax / 10), // Calculate a reasonable step size
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        // text: 'Monthly Orders',
      },
      datalabels: {
        anchor: 'end',      // Keep the anchor at the end of the bar
        align: 'end',       // Align the label to the end of the anchor point (top of the bar)
        padding: {
          top: 6,           // Add some padding to move the label above the bar
        },
        formatter:  (value) => value.toString(), // Display the actual value
        color: 'black',
        font: {
          size: 10,
          weight: 'bold',
        },
        clip: false,
      },
    },
  };
};
