import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AgGridWrapper from '../../../../../../components/AgGridWrapper/AgGridWrapper';
import useStore from '../../../../../../store';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { format } from 'date-fns'; // Use date-fns for formatting dates
import DatePicker from 'react-datepicker'; // Import react-datepicker
import 'react-datepicker/dist/react-datepicker.css'; // Import CSS for DatePicker

const ListOfNewClients = observer(() => {
  const { REPORT } = useStore();
  const navigate = useNavigate();
  const { fetchListOfNewClients } = REPORT;
  const [rowData, setRowData] = useState<any>([]);

  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), 0, 1)); // Default to the first day of the year
  const [endDate, setEndDate] = useState<Date>(new Date()); // Default to today

  const [columnDefs] = useState<any>([
    {
      headerName: 'Domain',
      field: 'domain', // Match the API response field
      filter: 'agTextColumnFilter', // Optional: add a text filter
    },
  ]);

  const fetchData = async () => {
    try {
      const from_date = format(startDate, 'yyyy-MM-dd');
      const to_date = format(endDate, 'yyyy-MM-dd');

      const data = await fetchListOfNewClients({ from_date, to_date });
      const domains = data.data.domains.map((domain: string) => ({ domain }));
      setRowData(domains); // Set the mapped data to rowData      
      console.log(domains, "data.data.domains")
    } catch (error) {
      console.error("Error fetching domain wise order count:", error);
      setRowData([]); // Handle the error by setting rowData to an empty array
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount or when date range changes
  }, [startDate, endDate]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignContent="center"
        spacing={2}
        sx={{ my: '20px'}}
      >
        <Box>
          {/* Date Range Filter */}
          <div style={{ display: 'flex', alignItems: 'center',  marginLeft: '10px' }}>
            <span style={{ marginRight: '10px' }}>From:</span>
            <DatePicker
              selected={startDate}
              onChange={(date: Date | null) => setStartDate(date || new Date())}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
            />
            <span style={{ margin: '0 10px' }}>To:</span>
            <DatePicker
              selected={endDate}
              onChange={(date: Date | null) => setEndDate(date || new Date())}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate} // Ensure 'to_date' is after 'from_date'
              dateFormat="yyyy-MM-dd"
            />
            {/* <Button 
              onClick={fetchData}
              variant="contained"
              color="primary"
              sx={{ marginLeft: '10px' }}
            >
              Apply
            </Button> */}
          </div>
        </Box>
      </Stack>

      <Box sx={{ width: '80%', margin: '0 auto' }}>
      {/* <div>
      {loading ? (
        <p>Loading domains...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        <div>
          <h3>List of New Clients</h3>
          <ul>
            {rowData.length > 0 ? (
              rowData.map((domain) => <li key={index}>{domain}</li>)
            ) : (
              <p>No domains available.</p>
            )}
          </ul>
        </div>
      )}
    </div> */}
     <AgGridWrapper
          type="clientSide"
          columnDefs={columnDefs}
          rowData={rowData}
        />
      </Box>
    </>
  );
});

export default ListOfNewClients;
