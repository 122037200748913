const API_PREFIX = `${process.env.REACT_APP_API_URL}api`

const API_URL = {
	LOGIN: `${API_PREFIX}/auth/login`,
    ME : `${API_PREFIX}/auth/me`,
    MY_PROFILE : `${API_PREFIX}/myprofile`,
    UPDATE_PROFILE_PASSWORD : `${API_PREFIX}/myprofile/update-password`,
	LOGOUT: `${API_PREFIX}/auth/logout`,
	FORGET_PASSWORD: `${API_PREFIX}/auth/forget-password`,
	RESET_PASSWORD: `${API_PREFIX}/auth/reset-password`,
	USER: {
		LIST: `${API_PREFIX}/users/list`,
		CREATE: `${API_PREFIX}/users/store`,
		VIEW: (id: number) => `${API_PREFIX}/users/show/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/users/update/${id}`,
		CHANGE_STATUS: (id: number): string => `${API_PREFIX}/users/updateUserStatus/${id}`,
		DELETE: (id: number) => `${API_PREFIX}/users/delete/${id}`,
		GET_LIST_ITEMS: `${API_PREFIX}/users/getListItems`,
		GET_USERS_LIST_ITEMS: `${API_PREFIX}/users/getUsersListItems`,
		CREATE_FORM_LIST_ITEMS: `${API_PREFIX}/users/createFormListItems`,
	},
	CLIENT: {
		LIST: `${API_PREFIX}/clients/list`,
		CREATE_FORM_LIST_ITEMS: `${API_PREFIX}/clients/createFormListItems`,
		CREATE: `${API_PREFIX}/clients`,
		VIEW: (id: number) => `${API_PREFIX}/clients/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/clients/${id}`,
		CHANGE_STATUS: (id: number): string => `${API_PREFIX}/clients/updateClientStatus/${id}`,
		DELETE: (id: number) => `${API_PREFIX}/clients/${id}`,
		GET_LIST_ITEMS: `${API_PREFIX}/clients/getListItems`,
		GET_CLIENTS : `${API_PREFIX}/clients/getClients`,
		GET_CLIENT_EMAILS: `${API_PREFIX}/clients/getEmails`,
		GET_BILLING_EMAILS:`${API_PREFIX}/clients/getBillingEmails`,
		CLIENTS_ORDERS_LIST : (id: number) => `${API_PREFIX}/clients/clientOrders/${id}`,
		CLIENT_SUMMARY: (id: number) => `${API_PREFIX}/clients/clientSummary/${id}`,
	},
	QUOTE: {
		LIST: `${API_PREFIX}/quotes/list`,
		CREATE: `${API_PREFIX}/quotes`,
		VIEW: (id: number) => `${API_PREFIX}/quotes/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/quotes/${id}`,
		CHANGE_STATUS: (id: number): string => `${API_PREFIX}/quotes/updateQuoteStatus/${id}`,
		DELETE: (id: number) => `${API_PREFIX}/quotes/${id}`,
		GET_LIST_ITEMS: `${API_PREFIX}/quotes/createFormListItems`,
		ATTRIBUTE_PRICE: `${API_PREFIX}/quotes/attributePrice`,
	},
	ESCALTION: {
		LIST: `${API_PREFIX}/escalations/list`,
		CREATE: `${API_PREFIX}/escalations`,
		VIEW: (id: number) => `${API_PREFIX}/escalations/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/escalations/${id}`,
		CREATE_FORM_LIST_ITEMS: `${API_PREFIX}/escalations/createFormListItems`,
		CHANGE_STATUS: (id: number) => `${API_PREFIX}/escalations/updateEscalationStatus/${id}`,
	},
	ORDER: {
		LIST: `${API_PREFIX}/orders/list`,
		VIEW: (id: number) => `${API_PREFIX}/orders/${id}`,
		UPDATE_ORDER: (id: number) => `${API_PREFIX}/orders/updateOrder/${id}`,
		ASSIGN_ORDER: (id: number) => `${API_PREFIX}/orders/assignOrder/${id}`,
		ORDER_TEMPLATE_STATUS: `${API_PREFIX}/orderTemplateStatus/getListItems`,
		UPDATE_ASSIGN_ORDER: (id: number) => `${API_PREFIX}/orders/updateAssignedOrder/${id}`,
		CHANGE_STATUS: (id: number): string => `${API_PREFIX}/orders/updateOrderStatus/${id}`,
		TEMPLATE_CHANGE_STATUS: (id: number): string => `${API_PREFIX}/orders/updateOrderTemplatesStatus/${id}`,
	},
	CHANGE_REQUEST: {
		LIST: (id: number) => `${API_PREFIX}/orders/orderChangeRequests/list/${id}`,
		CREATE_FORM_LIST_ITEMS: (id: number) => `${API_PREFIX}/orders/orderChangeRequests/createFormListItems/${id}`,
		CREATE: `${API_PREFIX}/orders/orderChangeRequests/store`,
		VIEW: (id: number) => `${API_PREFIX}/orders/orderChangeRequests/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/orders/orderChangeRequests/update/${id}`,
		CHANGE_STATUS: (id: number): string => `${API_PREFIX}/orders/orderChangeRequests/updateOrderChangeRequestStatus/${id}`,
	},
	BACKLOG: {
		LIST: (id: number) => `${API_PREFIX}/orders/orderBacklogs/list/${id}`,
		CREATE_FORM_LIST_ITEMS: (id: number) => `${API_PREFIX}/orders/orderBacklogs/createFormListItems/${id}`,
		CREATE: `${API_PREFIX}/orders/orderBacklogs/store`,
		VIEW: (id: number) => `${API_PREFIX}/orders/orderBacklogs/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/orders/orderBacklogs/update/${id}`,
		CHANGE_STATUS: (id: number): string => `${API_PREFIX}/orders/orderBacklogs/updateOrderBacklogStatus/${id}`,
	},
	DISCUSSION: {
		LIST: `${API_PREFIX}/discussions/list`,
		CREATE: `${API_PREFIX}/discussions`,
		VIEW: (id: number) => `${API_PREFIX}/discussions/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/discussions/${id}`,
		CHANGE_STATUS: (id: number): string => `${API_PREFIX}/discussions/updateDiscussionStatus/${id}`,
		DELETE: (id: number) => `${API_PREFIX}/discussions/${id}`,
	},
	ZONE: {
		LIST: `${API_PREFIX}/zones/list`,
		CREATE: `${API_PREFIX}/zones`,
		VIEW: (id: number) => `${API_PREFIX}/zones/${id}`,
		GET_CLIENT_EMAIL_BY_ORDER_ID: (id: any) => `${API_PREFIX}/zones/getOrderIdtoClientEmail/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/zones/${id}`,
	},
	FEEDBACK: {
		LIST: `${API_PREFIX}/feedbacks/list`,
	},
	GEO:{
		GET_LIST_ITEMS: `${API_PREFIX}/geos/getListItems`,
	},
	DEPARTMENT:{
		GET_LIST_ITEMS: `${API_PREFIX}/departments/getListItems`,
		LIST: `${API_PREFIX}/departments/list`,
		CREATE: `${API_PREFIX}/departments/store`,
		VIEW: (id: number) => `${API_PREFIX}/departments/show/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/departments/update/${id}`,
	},
	SHIFT:{
		GET_LIST_ITEMS: `${API_PREFIX}/shifts/getListItems`,
		LIST: `${API_PREFIX}/shifts/list`,
		CREATE: `${API_PREFIX}/shifts/store`,
		VIEW: (id: number) => `${API_PREFIX}/shifts/show/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/shifts/update/${id}`,
	},
	CHECKLIST:{
		LIST: `${API_PREFIX}/checklists/list`,
		CREATE_FORM_LIST_ITEMS: `${API_PREFIX}/checklists/createFormListItems`,
		CREATE: `${API_PREFIX}/checklists`,
		VIEW: (id: number) => `${API_PREFIX}/checklists/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/checklists/${id}`,
	},
	COUPON:{
		LIST: `${API_PREFIX}/coupons/list`,
		CREATE: `${API_PREFIX}/coupons/store`,
		VIEW: (id: number) => `${API_PREFIX}/coupons/show/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/coupons/update/${id}`,
		CHANGE_STATUS : `${API_PREFIX}/coupons/update-status`,
	},
	ROLE:{
		CREATE: `${API_PREFIX}/roles`,
		VIEW: (id: number) => `${API_PREFIX}/roles/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/roles/${id}`,
		CHANGE_STATUS : `${API_PREFIX}/roles/update-status`,
		LIST: `${API_PREFIX}/roles/list`,
		GET_LIST_ITEMS: `${API_PREFIX}/roles/getListItems`,
	},
	PERMISSION:{
		GET_LIST_ITEMS: `${API_PREFIX}/permissions/getListItems`,
	},
	ESCALATION_CATEGORY:{
		CREATE: `${API_PREFIX}/escalationCategories`,
		LIST: `${API_PREFIX}/escalationCategories/list`,
	},
	ESCALATION_SUBCATEGORY:{
		CREATE: `${API_PREFIX}/escalationSubCategories`,
		LIST: `${API_PREFIX}/escalationSubCategories/list`,
	},
	EXTRA_POINT:{
		GET_LIST_ITEMS: `${API_PREFIX}/extraPoints/createFormListItems`,
		LIST: `${API_PREFIX}/extraPoints/list`,
		CREATE: `${API_PREFIX}/extraPoints`,
		VIEW: (id: number) => `${API_PREFIX}/extraPoints/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/extraPoints/update/${id}`,
		CHANGE_STATUS: (id: number): string => `${API_PREFIX}/extraPoints/updateStatus/${id}`,
	},
	EARNING_POINT_LOG:{
		LIST: `${API_PREFIX}/earningPoints/list`,
	},
	DEFAULT_POINT:{
		GET_LIST_ITEMS: `${API_PREFIX}/defaultPointTypes/getListItems`,
		LIST: `${API_PREFIX}/defaultPointTypes/list`,
		VIEW: (id: number) => `${API_PREFIX}/defaultPointTypes/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/defaultPointTypes/update/${id}`,
	},
	DEFAULT_POINT_CUTOFF: {
		LIST: `${API_PREFIX}/defaultPointCutoffs/list`,
		CREATE: `${API_PREFIX}/defaultPointCutoffs`,
		VIEW: (id: number) => `${API_PREFIX}/defaultPointCutoffs/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/defaultPointCutoffs/update/${id}`,
	},
	ESP: {
		LIST: `${API_PREFIX}/esps/list`,
		GET_LIST_ITEMS: `${API_PREFIX}/esps/getFormListItems`,
		CREATE: `${API_PREFIX}/esps`,
		VIEW: (id: number) => `${API_PREFIX}/esps/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/esps/update/${id}`,
		CHANGE_STATUS: (id: number): string => `${API_PREFIX}/esps/updateEspStatus/${id}`,
	},
	ADDON: {
		LIST: `${API_PREFIX}/addons/list`,
		GET_LIST_ITEMS: `${API_PREFIX}/addons/getFormListItems`,
		CREATE: `${API_PREFIX}/addons`,
		VIEW: (id: number) => `${API_PREFIX}/addons/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/addons/update/${id}`,
		CHANGE_STATUS: (id: number): string => `${API_PREFIX}/addons/updateAddonStatus/${id}`,
	},
	LAYOUT: {
		LIST: `${API_PREFIX}/layouts/list`,
		GET_LIST_ITEMS: `${API_PREFIX}/layouts/getFormListItems`,
		CREATE: `${API_PREFIX}/layouts`,
		VIEW: (id: number) => `${API_PREFIX}/layouts/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/layouts/update/${id}`,
		CHANGE_STATUS: (id: number): string => `${API_PREFIX}/layouts/updateLayoutStatus/${id}`,
	},
	TIMESLOT: {
		LIST: `${API_PREFIX}/timeslots/list`,
		GET_LIST_ITEMS: `${API_PREFIX}/timeslots/getFormListItems`,
		CREATE: `${API_PREFIX}/timeslots`,
		VIEW: (id: number) => `${API_PREFIX}/timeslots/${id}`,
		UPDATE: (id: number) => `${API_PREFIX}/timeslots/update/${id}`,
		CHANGE_STATUS: (id: number): string => `${API_PREFIX}/timeslots/updateTimeslotStatus/${id}`,
	},
	CURRENCY: {
		CURRENCY_CONVERSION_LIST: `${API_PREFIX}/currencyConversionRates/list`,
		UPDATE: `${API_PREFIX}/currencyConversionRates/update`,
	},
	REPORTS: {
		TOTAL_ORDERS: `${API_PREFIX}/getData/orders`,
		TOTAL_TEMPLATE: `${API_PREFIX}/getData/templates`,
		DOMAIN_HIGHEST_ORDERS: `${API_PREFIX}/getData/domains-highest-orders`,
		ORDERS_WITH_BACKLOGS: `${API_PREFIX}/getData/orders-with-backlogs`,
		ORDERS_WITH_CHANGE_REQUEST: `${API_PREFIX}/getData/orders-with-change-requests`,
		TOTAL_REVENUE: `${API_PREFIX}/getData/total-revenue`,
		DOMAIN_WITH_HIGHEST_REVENUE: `${API_PREFIX}/getData/domains-highest-revenue`,
		NEW_CLIENTS: `${API_PREFIX}/getData/list-of-new-clients`,
		ORDERS_WITH_MORE_THAN_5_ITERATIONS: `${API_PREFIX}/getData/orders-more-than-5-iterations`,
		DEVELOPERS_WITH_ORDERS_TEMPLATES: `${API_PREFIX}/getData/developers-with-orders-templates`,
	}
};

export default API_URL;
