import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import loadingStore from "../LoadingStore/LoadingStore";
import { format } from 'date-fns'; // You can use date-fns to format dates easily

export default class ReportStore {
  constructor() {
    makeAutoObservable(this);
  }


  public fetchMonthlyOrders = async (payload: { from_date?: string; to_date?: string }): Promise<any> => {
    try {
      loadingStore.setAppLoading(true);
  
      // If from_date or to_date are missing, set default values
      const today = new Date();
      const defaultFromDate = format(new Date(today.getFullYear(), 0, 1), 'yyyy-MM-dd'); // First day of the current year
      const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date
  
      // Use default dates if not provided in payload
      const from_date = payload.from_date || defaultFromDate;
      const to_date = payload.to_date || defaultToDate;
  
      const finalPayload = { from_date, to_date };
  
      console.log("fetchMonthlyOrders Payload:", finalPayload);
  
      const { data } = await axios.post(API_URL.REPORTS.TOTAL_ORDERS, finalPayload);
  
      console.log("fetchMonthlyOrders Response:", data);
  
      loadingStore.setAppLoading(false);
  
      return Promise.resolve(data);
    } catch (err: any) {
      loadingStore.setAppLoading(false);
  
      // Ensure the error is logged correctly
      console.error("fetchMonthlyOrders Error:", JSON.stringify(err.response?.data || err.message));
  
      // Returning the error in readable format
      return Promise.reject(JSON.stringify(err.response?.data || err.message));
    }
  };
  
  public fetchMonthlyTemplates = async (payload: { from_date: string; to_date: string }): Promise<any> => {
    try {
      loadingStore.setAppLoading(true);

      console.log("fetchMonthlyTemplates Payload:", payload);

      const { data } = await axios.post(API_URL.REPORTS.TOTAL_TEMPLATE, payload);

      console.log("fetchMonthlyTemplates Response:", data);

      loadingStore.setAppLoading(false);

      return Promise.resolve(data);
    } catch (err: any) {
      loadingStore.setAppLoading(false);

      console.error("fetchMonthlyTemplates Error:", JSON.stringify(err.response?.data || err.message));

      return Promise.reject(JSON.stringify(err.response?.data || err.message));
    }
  };

  public fetchMonthlyBacklogs = async (payload: { from_date?: string; to_date?: string }): Promise<any> => {
    try {
      loadingStore.setAppLoading(true);

      console.log("fetchMonthlyBacklogs Payload:");

      const { data } = await axios.post(API_URL.REPORTS.ORDERS_WITH_BACKLOGS, payload);
    //   const data = {
    //     "error": false,
    //     "message": "Total orders fetch successfully.",
    //     "data": {
    //         "monthly_counts": [
    //             {
    //                 "month": "Feb",
    //                 "count": 6,
    //                 "templateCount":2,
    //                 "year": "2024"
    //             },
    //             {
    //                 "month": "Mar",
    //                 "count": 2,
    //                 "templateCount":3,
    //                 "year": "2024"
    //             },
    //             {
    //                 "month": "Apr",
    //                 "count": 1,
    //                 "templateCount":6,
    //                 "year": "2024"
    //             }
    //         ]
    //     }
    // };
      console.log("fetchMonthlyBacklogs Response:", data);

      loadingStore.setAppLoading(false);

      return Promise.resolve(data);
    } catch (err: any) {
      loadingStore.setAppLoading(false);

      console.error("fetchMonthlyBacklogs Error:", JSON.stringify(err.response?.data || err.message));

      return Promise.reject(JSON.stringify(err.response?.data || err.message));
    }
  };

  public fetchMonthlyBacklogsVsOrder = async (payload: { from_date?: string; to_date?: string }): Promise<any> => {
    try {
      loadingStore.setAppLoading(true);

      console.log("fetchMonthlyBacklogsVsOrder Payload:");

      const { data } = await axios.post(API_URL.REPORTS.ORDERS_WITH_CHANGE_REQUEST, payload);
    //   const data = {
    //     "error": false,
    //     "message": "Total orders fetch successfully.",
    //     "data": {
    //         "monthly_counts": [
    //             {
    //                 "month": "Feb",
    //                 "count": 6,
    //                 "templateCount":2,
    //                 "year": "2024"
    //             },
    //             {
    //                 "month": "Mar",
    //                 "count": 2,
    //                 "templateCount":3,
    //                 "year": "2024"
    //             },
    //             {
    //                 "month": "Apr",
    //                 "count": 1,
    //                 "templateCount":6,
    //                 "year": "2024"
    //             }
    //         ]
    //     }
    // };
      console.log("fetchMonthlyBacklogsVsOrder Response:", data);

      loadingStore.setAppLoading(false);

      return Promise.resolve(data);
    } catch (err: any) {
      loadingStore.setAppLoading(false);

      console.error("fetchMonthlyBacklogsVsOrder Error:", JSON.stringify(err.response?.data || err.message));

      return Promise.reject(JSON.stringify(err.response?.data || err.message));
    }
  };

  public fetchDomainWiseOrderCount = async (payload: { from_date?: string; to_date?: string }): Promise<any> => {
    try {
      loadingStore.setAppLoading(true);
  
      // If from_date or to_date are missing, set default values
      const today = new Date();
      const defaultFromDate = format(new Date(today.getFullYear(), 0, 1), 'yyyy-MM-dd'); // First day of the current year
      const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date
  
      // Use default dates if not provided in payload
      const from_date = payload.from_date;
      const to_date = payload.to_date;
  
      const finalPayload = { from_date, to_date };
      const { data } = await axios.post(API_URL.REPORTS.DOMAIN_HIGHEST_ORDERS, finalPayload);  
      loadingStore.setAppLoading(false);
  
      return Promise.resolve(data);
    } catch (err: any) {
      loadingStore.setAppLoading(false);
  
      // Ensure the error is logged correctly
      console.error("fetchMonthlyOrders Error:", JSON.stringify(err.response?.data || err.message));
  
      // Returning the error in readable format
      return Promise.reject(JSON.stringify(err.response?.data || err.message));
    }
  };
  // total revenue graph
  public fetchTotalRevenue = async (payload: { from_date?: string; to_date?: string }): Promise<any> => {
    try {
      loadingStore.setAppLoading(true);
  
      // If from_date or to_date are missing, set default values
      const today = new Date();
      const defaultFromDate = format(new Date(today.getFullYear(), 0, 1), 'yyyy-MM-dd'); // First day of the current year
      const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date
  
      // Use default dates if not provided in payload
      const from_date = payload.from_date || defaultFromDate;
      const to_date = payload.to_date || defaultToDate;
  
      const finalPayload = { from_date, to_date };
  
      console.log("fetchTotalRevenue Payload:", finalPayload);
  
      const { data } = await axios.post(API_URL.REPORTS.TOTAL_REVENUE, finalPayload);
  
      console.log("fetchTotalRevenue Response:", data);
  
      loadingStore.setAppLoading(false);
  
      return Promise.resolve(data);
    } catch (err: any) {
      loadingStore.setAppLoading(false);
  
      // Ensure the error is logged correctly
      console.error("fetchTotalRevenue Error:", JSON.stringify(err.response?.data || err.message));
  
      // Returning the error in readable format
      return Promise.reject(JSON.stringify(err.response?.data || err.message));
    }
  };

   // list-of-new-clients
  public fetchListOfNewClients = async (payload: { from_date?: string; to_date?: string }): Promise<any> => {
    try {
      loadingStore.setAppLoading(true);
  
      // If from_date or to_date are missing, set default values
      const today = new Date();
      const defaultFromDate = format(new Date(today.getFullYear(), 0, 1), 'yyyy-MM-dd'); // First day of the current year
      const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date
  
      // Use default dates if not provided in payload
      const from_date = payload.from_date;
      const to_date = payload.to_date ;
  
      const finalPayload = { from_date, to_date };
  
      console.log("fetchListOfNewClients Payload:", finalPayload);
  
      const { data } = await axios.post(API_URL.REPORTS.NEW_CLIENTS, finalPayload);
  
      console.log("fetchListOfNewClients Response:", data);
  
      loadingStore.setAppLoading(false);

      return Promise.resolve(data);
    } catch (err: any) {
      loadingStore.setAppLoading(false);
  
      // Ensure the error is logged correctly
      console.error("fetchListOfNewClients Error:", JSON.stringify(err.response?.data || err.message));
  
      // Returning the error in readable format
      return Promise.reject(JSON.stringify(err.response?.data || err.message));
    }
  };

     // ORDERS_WITH_MORE_THAN_5_ITERATIONS
     public fetchOrderListMoreThanFiveIterations = async (payload: { from_date?: string; to_date?: string }): Promise<any> => {
      try {
        loadingStore.setAppLoading(true);
    
        // If from_date or to_date are missing, set default values
        const today = new Date();
        const defaultFromDate = format(new Date(today.getFullYear(), 0, 1), 'yyyy-MM-dd'); // First day of the current year
        const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date
    
        // Use default dates if not provided in payload
        const from_date = payload.from_date;
        const to_date = payload.to_date ;
    
        const finalPayload = { from_date, to_date };
    
        console.log("fetchOrderListMoreThanFiveIterations Payload:", finalPayload);
    
        const { data } = await axios.post(API_URL.REPORTS.ORDERS_WITH_MORE_THAN_5_ITERATIONS, finalPayload);
    
        console.log("fetchOrderListMoreThanFiveIterations Response:", data);
    
        loadingStore.setAppLoading(false);
    
        return Promise.resolve(data);
      } catch (err: any) {
        loadingStore.setAppLoading(false);
    
        // Ensure the error is logged correctly
        console.error("fetchOrderListMoreThanFiveIterations Error:", JSON.stringify(err.response?.data || err.message));
    
        // Returning the error in readable format
        return Promise.reject(JSON.stringify(err.response?.data || err.message));
      }
    };
    
  public fetchDevelopersWithOrdersTemplates = async (payload: { from_date?: string; to_date?: string }): Promise<any> => {
    try {
      loadingStore.setAppLoading(true);
  
      // If from_date or to_date are missing, set default values
      const today = new Date();
      const defaultFromDate = format(new Date(today.getFullYear(), 0, 1), 'yyyy-MM-dd'); // First day of the current year
      const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date
  
      // Use default dates if not provided in payload
      const from_date = payload.from_date;
      const to_date = payload.to_date ;
  
      const finalPayload = { from_date, to_date };
  
      console.log("fetchDevelopersWithOrdersTemplates Payload:", finalPayload);
  
      const { data } = await axios.post(API_URL.REPORTS.DEVELOPERS_WITH_ORDERS_TEMPLATES, finalPayload);
  
      console.log("fetchDevelopersWithOrdersTemplates Response:", data);
  
      loadingStore.setAppLoading(false);
  
      return Promise.resolve(data);
    } catch (err: any) {
      loadingStore.setAppLoading(false);
  
      // Ensure the error is logged correctly
      console.error("fetchDevelopersWithOrdersTemplates Error:", JSON.stringify(err.response?.data || err.message));
  
      // Returning the error in readable format
      return Promise.reject(JSON.stringify(err.response?.data || err.message));
    }
  };

  public fetchDomainWithHighestRevenue = async (payload: { from_date?: string; to_date?: string }): Promise<any> => {
    try {
      loadingStore.setAppLoading(true);
  
      // If from_date or to_date are missing, set default values
      const today = new Date();
      const defaultFromDate = format(new Date(today.getFullYear(), 0, 1), 'yyyy-MM-dd'); // First day of the current year
      const defaultToDate = format(today, 'yyyy-MM-dd'); // Today's date
  
      // Use default dates if not provided in payload
      const from_date = payload.from_date;
      const to_date = payload.to_date ;
  
      const finalPayload = { from_date, to_date };
  
      console.log("fetchDomainWithHighestRevenue Payload:", finalPayload);
  
      const { data } = await axios.post(API_URL.REPORTS.DOMAIN_WITH_HIGHEST_REVENUE, finalPayload);
  
      console.log("fetchDomainWithHighestRevenue Response:", data);
  
      loadingStore.setAppLoading(false);
  
      return Promise.resolve(data);
    } catch (err: any) {
      loadingStore.setAppLoading(false);
  
      // Ensure the error is logged correctly
      console.error("fetchDomainWithHighestRevenue Error:", JSON.stringify(err.response?.data || err.message));
  
      // Returning the error in readable format
      return Promise.reject(JSON.stringify(err.response?.data || err.message));
    }
  };
}
