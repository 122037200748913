import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { chartOptions } from '../commonChartOptions';
import { observer } from 'mobx-react';
import useStore from '../../../../../../store';
import { format } from 'date-fns'; // Use date-fns for formatting dates

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Define the type for order data
interface OrderData {
  month: string;
  count: number;
  year: string;

}

const OrderGraph: React.FC = () => {
  const { REPORT } = useStore();
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), 0, 1));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [orderData, setOrderData] = useState<OrderData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(''); // State for error messages

  // Helper function to format the date to 'YYYY-MM-DD'
  const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

  const fetchData = async (from_date: string, to_date: string) => {
    try {
      const response = await REPORT.fetchMonthlyOrders({ from_date, to_date });
      if (!response.error) {
        setOrderData(response.data.monthly_counts);
      }
    } catch (err) {
      console.error('Failed to fetch data:', err);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      const from_date = formatDate(startDate);
      const to_date = formatDate(endDate);
      
      // Ensure that the date range is within one year
      const oneYearLater = new Date(startDate);
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
      if (endDate > oneYearLater) {
        setError('Please select a date range within one year.');
        return;
      } else {
        setError(''); // Clear error if valid
      }

      console.log('Fetching templates from', from_date, 'to', to_date);
      fetchData(from_date, to_date);
    }
  }, [startDate, endDate, REPORT]);

  const chartData = {
    labels: orderData.map((data) => `${data.month}'${data.year.slice(-2)}`), // Format to "Apr'24"
    datasets: [
      {
        label: 'Number of Orders (Month Wise)',
        data: orderData.map((data) => data.count),
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
    ],
  };
  const options = chartOptions(orderData.map((data) => data.count));

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <span style={{ margin: '0 10px' }}>From</span>
        <DatePicker
          selected={startDate}
          onChange={(date: Date | null) => {
            if (date) setStartDate(date);
          }}
          selectsStart
          startDate={startDate}
          endDate={endDate}
        />
        <span style={{ margin: '0 10px' }}>to</span>
        <DatePicker
          selected={endDate}
          onChange={(date: Date | null) => {
            if (date) setEndDate(date);
          }}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate} // Ensure 'to' date is after 'from' date
        />
      </div>
      {error && <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>} {/* Display error message */}
      {orderData.length > 0 ? (
        <Bar data={chartData} options={options} />
      ) : (
        <p style={{ textAlign: 'center' }}>No data available for the selected range</p>
      )}
    </div>
  );
};

export default OrderGraph;
